import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routes = [
  // {
  //   path: "/",
  //   name: "AccessRestricted",
  //   component: () => import('../views/accessRestricted')
  // },
  // {
  //   path: "/notFound",
  //   name: "notFound",
  //   component: () => import('../views/notFound')
  // },
  {
    path: "/",
    name: "index",
    component: () => import('../views/index/index')
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import('../views/index/agreement')
  },
  {
    path: "/participate",
    name: "participate",
    component: () => import('../views/index/participate')
  },
  {
    path: "/participateUSDM",
    name: "participateUSDM",
    component: () => import('../views/index/participateUSDM')
  },
  {
    path: "/introduce",
    name: "introduce",
    component: () => import('../views/introduce/index')
  },
  {
    path: "/firing",
    name: "firing",
    component: () => import('../views/firing/index')
  },
  {
    path: "/team",
    name: "team",
    component: () => import('../views/team/index')
  },
  {
    path: "/my",
    name: "my",
    component: () => import('../views/my/index')
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: () => import('../views/my/withdrawal')
  },
  {
    path: "/withdrawalRecords",
    name: "withdrawalRecords",
    component: () => import('../views/my/withdrawalRecords')
  },
  {
    path: "/withdrawalDetails",
    name: "withdrawalDetails",
    component: () => import('../views/my/withdrawalDetails')
  },
  {
    path: "/purchaseRecord",
    name: "purchaseRecord",
    component: () => import('../views/my/purchaseRecord')
  },
  {
    path: "/rewardDetails",
    name: "rewardDetails",
    component: () => import('../views/my/rewardDetails')
  },
  {
    path: "/sponsoringFriends",
    name: "sponsoringFriends",
    component: () => import('../views/my/sponsoringFriends')
  },
  {
    path: "/inviteFriends",
    name: "inviteFriends",
    component: () => import('../views/my/inviteFriends')
  },
  {
    path: "/assignResults",
    name: "assignResults",
    component: () => import('../views/my/assignResults')
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import('../views/index/notice')
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () => import('../views/my/contactUs')
  },
  {
    path: "/withdrawalButton",
    name: "withdrawalButton",
    component: () => import('../views/withdrawal/withdrawalButton')
  },
  {
    path: "/bindEmail",
    name: "bindEmail",
    component: () => import('../views/my/bindEmail')
  },
  {
    path: "/test",
    name: "Test",
    component: () => import('../views/test')
  },
  {
    path: "/treeTest",
    name: "treeTest",
    component: () => import('../views/treeTest.vue')
  },
  {
    path: "/Record",
    name: "Record",
    component: () => import('../views/team/record')
  },
  {
    path: "/unbindEmail",
    name: "unbindEmail",
    component: () => import('../views/my/unbindEmail')
  },
  {
    path: "/web3Test",
    name: "web3Test",
    component: () => import('../views/web3Test')
  },
  {
    path: "/loginPass",
    name: "loginPass",
    component: () => import('../views/my/loginPass')
  },
  {
    path: "/bindRecord",
    name: "bindRecord",
    component: () => import('../views/my/bindRecord'),
  },
  {
    path: "/problemFeedback",
    name: "problemFeedback",
    component: () => import('../views/my/problemFeedback'),
  },
  // {
  //   path: "/recharge",
  //   name: "recharge",
  //   component: () => import('../views/recharge'),
  // },
  {
    path: "/tree",
    name: "tree",
    component: () => import('../views/tree'),
  },
  {
    path: "/superDetails",
    name: "superDetails",
    component: () => import('../views/my/superDetails'),
  },
  {
    path: "/upgradeTime",
    name: "upgradeTime",
    component: () => import('../views/my/upgradeTime'),
  },
  {
    path: "/withdrawalInternally",
    name: "withdrawalInternally",
    component: () => import('../views/my/withdrawalInternally'),
  },
  {
    path: "/exchangeRecords",
    name: "exchangeRecords",
    component: () => import('../views/my/exchangeRecords'),
  },
  {
    path: "/usdtWithdrawal",
    name: "usdtWithdrawal",
    component: () => import('../views/my/usdtWithdrawal'),
  },
  {
    path: "/usdmWithdrawal",
    name: "usdmWithdrawal",
    component: () => import('../views/my/usdmWithdrawal'),
  },
  {
    path: "/noticeInfo",
    name: "noticeInfo",
    component: () => import('../views/index/noticeInfo'),
  },
]

const router = new Router({
  // 这里配置的是路由和组件的映射关系, 是一个数组.
  routes,
  scrollBehavior(to, from, savedPosition) {
    //x代表横坐标，y代表纵坐标
    return { x: 0,y: 0 };
  },
  mode: 'history'
})

export default router
